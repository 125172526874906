<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="tab-content scrollable">
    <div class="pa-3">
      <b-textfield v-model="details.name" label="Posição"></b-textfield>
    </div>
    <span class="divider"></span>
    <div
      v-if="currentStage === stages.PROJECTS || currentStage === stages.PLACEDS"
      class="pa-3"
    >
      <div class="subheader">Processo de seleção</div>
      <div
        class="selection-button pa-2 d-flex bg-contrast mt-1 shadow gap-16 align-center"
      >
        <div class="flex-grow-1">
          <em class="fal fa-users mr-1"></em>
          <strong class="paragraph md">{{ details.candidancy.length }}</strong>
        </div>

        <div class="status-label paragraph xs font-weight-400 info">
          {{ details.candidancy_status }}
        </div>
        <button class="btn text sm icon" @click="openCandidateList">
          <em class="fal fa-external-link-square"></em>
        </button>
      </div>
    </div>
    <template v-if="currentStage === stages.PLACEDS">
      <div class="pa-3">
        <div class="subheader">Placed</div>
        <div class="list-item-content mt-2">
          <b-avatar
            size="md"
            :name="details.candidateWinner?.[0].name"
            class="candidate-picture"
          >
            <img :alt="details.candidateWinner?.[0].name" />
          </b-avatar>
          <div class="flex-grow-1">
            <div class="f-paragraph-md font-weight-500">
              {{ details.candidateWinner?.[0].name }}
            </div>
            <div class="f-paragraph-xs text-color-light">
              {{ details.candidateWinner?.[0].contactEmail[0]?.email }}
            </div>
          </div>
        </div>
        <div class="mt-2">
          <span class="divider"></span>
        </div>
        <div class="mt-2">
          <div class="row">
            <div class="col-xs-12 col-sm-11">
              <b-input-file
                id="attach-replaceFile"
                v-model="details.fileContract"
                label="Contrato placed"
              ></b-input-file>
            </div>
            <div v-if="details.contract" class="col-xs-12 col-sm-1 mt-3">
              <b-tooltip>
                <template #activator="{ on }">
                  <a
                    :href="details.contract['@microsoft.graph.downloadUrl']"
                    class="btn icon sm"
                    v-on="on"
                  >
                    <em class="fal fa-link"></em>
                  </a>
                </template>
                {{ details.contract.name }}
              </b-tooltip>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <b-autocomplete
                v-model="details.monitoringFraquencyId"
                label="Periodicidade de acompanhamento"
                :required="false"
                :options="positionStatus.monitoringFraquency"
                option-text="name"
                option-value="id"
              />
            </div>
            <div class="col-xs-12 col-sm-6">
              <b-datepicker
                v-model="details.entryDatePlaced"
                label="Data de entrada - Placed"
                placeholder="Selecione..."
                class="mb-0"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <span class="divider"></span>
    <div class="pa-3">
      <div class="subheader">Empresa</div>
      <b-autocomplete
        v-if="!details.id"
        v-model="company"
        :options="companies"
        class="mb-0 mt-2"
        placeholder="Selecione a empresa"
        option-text="name"
        option-id="id"
        @input="searchCompany"
        :suggest="() => $emit('openCompany', 'new')"
      >
        <template #append-outer>
          <button class="btn icon sm" @click="$emit('openCompany', 'new')">
            <em class="fal fa-plus"></em>
          </button>
        </template>
      </b-autocomplete>
      <div
        v-if="company.id"
        class="pa-2 d-flex bg-contrast mt-1 shadow gap-16"
        @click="openCompany()"
      >
        <div class="flex-grow-1">
          <em class="fas mr-1" :class="iconPersona"></em>
          <strong class="paragraph md">{{ company?.name }}</strong>
          <span class="divider mt-1 mb-1"></span>
          <a href="#" class="d-flex align-center">
            <span class="text-color-light">
              <em class="fal fa-globe"></em>
            </span>
            <span class="ml-1 paragraph xs lh-1_1">{{ company.website }}</span>
          </a>
          <a href="#" class="d-flex align-center mt-1">
            <span class="text-color-light">
              <em class="fab fa-linkedin-in"></em>
            </span>
            <span class="ml-1 paragraph xs lh-1_1">{{ company.linkedin }}</span>
          </a>
          <span class="divider mt-1 mb-1"></span>
          <div class="d-flex align-center justify-between gap-8">
            <div class="d-flex align-center">
              <span class="text-color-light">
                <em class="fal fa-crosshairs"></em>
              </span>
              <span
                class="ml-1 paragraph xs lh-1_1 truncate truncate-after lines-1"
                >{{ company?.sector?.name }}</span
              >
            </div>
            <b-tooltip>
              <template #activator="{ on }">
                <div class="d-flex align-center" v-on="on">
                  <span class="text-color-light">
                    <em class="fal fa-building"></em>
                  </span>
                  <span
                    class="ml-1 paragraph xs lh-1_1 truncate truncate-after lines-1"
                    >{{ company?.category?.name }}</span
                  >
                </div>
              </template>
              Porte
            </b-tooltip>
            <b-tooltip>
              <template #activator="{ on }">
                <div class="d-flex align-center" v-on="on">
                  <span class="text-color-light">
                    <em class="fal fa-users"></em>
                  </span>
                  <span
                    class="ml-1 paragraph xs lh-1_1 truncate truncate-after lines-1"
                    >{{ company?.billing?.name }}</span
                  >
                </div>
              </template>
              Funcionários
            </b-tooltip>
          </div>
        </div>
        <div class="thumb">
          <img :src="company.logo" :alt="company?.name" />
        </div>
      </div>
      <div
        v-if="company.contacts && company.contacts.length"
        class="pa-1 d-flex justify-start bg-contrast mt-1 shadow gap-8"
      >
        <b-avatar
          v-for="contact in company.contacts"
          :key="contact.id"
          :name="contact?.name"
          size="xs"
          class="cursor-pointer"
          @click="openUserModal(contact)"
        >
          <img v-if="contact.photo" :src="contact.photo" :alt="contact?.name" />
        </b-avatar>
      </div>
    </div>
    <template v-if="currentStage === stages.LEAD">
      <span class="divider"></span>
      <div class="pa-3">
        <b-slider
          v-model="details.chanceToBecomeDeal"
          label="Probabilidade de virar um negócio"
          class="mb-0"
        />
      </div>
    </template>
    <template v-if="currentStage === stages.BUSINESS">
      <span class="divider"></span>
      <div class="pa-3">
        <b-slider
          v-model="details.chanceToCloseDeal"
          label="Probabilidade de fechamento deste negócio"
        />
        <b-datepicker
          v-model="details.expectedDateToCloseDeal"
          label="Possível data de fechamento do negócio"
          placeholder="Selecione..."
          class="mb-0"
        />
      </div>
    </template>
    <span class="divider"></span>
    <div class="pa-3">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <b-textfield
            v-model="details.code"
            label="Código"
            class="cursor-disabled"
            readonly
          />
        </div>
        <div class="col-xs-12 col-sm-6">
          <b-autocomplete
            v-if="currentStage === stages.PROSPECT"
            v-model="details.prospectStatusId"
            label="Status"
            :options="positionStatus.prospect"
            option-text="name"
            option-value="id"
          >
            <template #append-outer>
              <b-tooltip>
                <template #activator="{ on }">
                  <button class="btn icon sm" v-on="on">
                    <em class="fal fa-key-skeleton"></em>
                  </button>
                </template>
                Confidencial
              </b-tooltip>
            </template>
          </b-autocomplete>
          <b-autocomplete
            v-if="currentStage === stages.PLACEDS"
            v-model="details.placedStatusId"
            label="Status"
            :options="positionStatus.placed"
            option-text="name"
            option-value="id"
          >
            <template #append-outer>
              <b-tooltip>
                <template #activator="{ on }">
                  <button class="btn icon sm" v-on="on">
                    <em class="fal fa-key-skeleton"></em>
                  </button>
                </template>
                Confidencial
              </b-tooltip>
            </template>
          </b-autocomplete>
          <b-autocomplete
            v-if="currentStage === stages.PROJECTS"
            v-model="details.projectStatusId"
            label="Status"
            :options="positionStatus.project"
            option-text="name"
            option-value="id"
          >
            <template #append-outer>
              <b-tooltip>
                <template #activator="{ on }">
                  <button class="btn icon sm" v-on="on">
                    <em class="fal fa-key-skeleton"></em>
                  </button>
                </template>
                Confidencial
              </b-tooltip>
            </template>
          </b-autocomplete>
          <b-autocomplete
            v-if="currentStage === stages.LEAD"
            v-model="details.leadStatusId"
            label="Status"
            :options="positionStatus.lead"
            option-text="name"
            option-value="id"
          >
            <template #append-outer>
              <b-tooltip>
                <template #activator="{ on }">
                  <button class="btn icon sm" v-on="on">
                    <em class="fal fa-key-skeleton"></em>
                  </button>
                </template>
                Confidencial
              </b-tooltip>
            </template>
          </b-autocomplete>
          <b-autocomplete
            v-if="currentStage === stages.BUSINESS"
            v-model="details.businessStatusId"
            label="Status"
            :options="positionStatus.business"
            option-text="name"
            option-value="id"
          >
            <template #append-outer>
              <b-tooltip>
                <template #activator="{ on }">
                  <button class="btn icon sm" v-on="on">
                    <em class="fal fa-key-skeleton"></em>
                  </button>
                </template>
                Confidencial
              </b-tooltip>
            </template>
          </b-autocomplete>
        </div>
      </div>
      <b-autocomplete
        v-if="currentStage === stages.BUSINESS"
        v-model="details.businessRejectStatusId"
        label="Qual foi o motivo da perda da proposta?"
        class="flex-grow-1"
        :options="positionStatus.businessReject"
        option-text="name"
        option-value="id"
        :disabled="!businessRejected"
      />
      <div class="row">
        <div class="col-xs-12">
          <b-autocomplete
            v-model="details.originId"
            label="Origem"
            placeholder="Selecione a origem"
            class="flex-grow-1"
            :options="origins"
            option-text="name"
            option-value="id"
            :disabled="currentStage === stages.PROSPECT"
          />
        </div>
      </div>
      <b-autocomplete
        v-if="currentStage === stages.BUSINESS"
        v-model="details.pipelineServiceTypeId"
        label="Tipo de serviço"
        class="flex-grow-1"
        :options="serviceTypes"
        option-text="name"
        option-value="id"
      />
      <b-textfield
        v-model="details.note"
        label="Observações"
        sublabel="Opcional"
        :multiline="true"
      />
    </div>
    <span class="divider"></span>
    <div class="pa-3">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <b-autocomplete
            v-model="details.internalFupPriorityId"
            label="Periodicidade FUP - interno"
            :required="false"
            :options="priorities"
            option-text="name"
            option-value="id"
          />
        </div>
        <div class="col-xs-12 col-sm-6">
          <b-autocomplete
            v-model="details.customerFupPriorityId"
            label="Periodicidade FUP - cliente"
            :required="false"
            :options="priorities"
            option-text="name"
            option-value="id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from '../../helpers'

export default {
  name: 'DetalhesComponent',
  mixins: [mixins],
  props: ['details', 'currentStage'],
  data() {
    return {
      candidancy: ''
    }
  },
  computed: {
    ...mapGetters({
      companies: 'company/companies',
      stages: 'home/stages',
      origins: 'home/origins',
      serviceTypes: 'home/serviceTypes',
      priorities: 'home/priorities',
      positionStatus: 'home/positionStatus',
      currentPipeline: 'jobs/currentPipeline',
      currentFolder: 'attachments/currentFolder'
    }),
    company: {
      get() {
        return this.$store.getters['company/currentCompany']
      },
      set(company) {
        this.$store.dispatch('company/setCurrentCompany', company)
      }
    },
    businessRejected() {
      const [rejectStatus] = this.positionStatus.business.filter(
        (status) => status?.name.trim().toUpperCase() === 'PROPOSTA PERDIDA'
      )

      if (!rejectStatus) return false

      return this.details.businessStatusId === rejectStatus.id
    },
    iconPersona() {
      if (!this.company.persona) return ''
      switch (this.company.persona.id) {
        case 1:
          return 'fa-family text-color-azul-celeste'
        case 2:
          return 'fa-rotate text-color-lavanda'
        case 3:
          return 'fa-burst text-color-manga'
        case 4:
          return 'fa-hand-holding-seedling text-color-esmeralda'
        case 5:
          return 'fa-arrow-right-arrow-left text-color-coral'
        default:
          return ''
      }
    }
  },
  watch: {
    'details.businessStatusId'() {
      if (this.businessRejected) return
      // eslint-disable-next-line vue/no-mutating-props
      this.details.businessRejectStatusId = ''
    }
  },
  async mounted() {
    this.loadPriorities()
    this.loadOrigins()
    this.loadServiceTypes()
    this.loadStatus()
  },
  methods: {
    ...mapActions({
      loadPriorities: 'home/loadPriorities',
      loadOrigins: 'home/loadOrigins',
      loadServiceTypes: 'home/loadServiceTypes',
      loadStatus: 'home/loadStatus',
      loadCandidates: 'jobs/loadCandidates',
      setCurrentPipeline: 'jobs/setCurrentPipeline',
      loadTypeHistory: 'history/loadTypeHistory',
      loadCompanies: 'company/loadCompanies',
      countCandidates: 'jobs/loadCountCandidates'
    }),
    openCompany() {
      this.$emit('openCompany', this.company)
    },
    openUserModal(data) {
      this.$emit('openUser', {
        id: data?.id,
        name: data?.name,
        email: data.email,
        phone: data.phone,
        photo: data.photo,
        company: {
          name: this.company?.name
        },
        type: 'placeds',
        color: '#c06fdd'
      })
    },
    async openCandidateList() {
      await this.loadCandidates({ pipelineId: this.details.id })
      await this.setCurrentPipeline({
        id: this.details.id,
        name: this.details.name,
        companyId: this.details.companyId,
        originId: this.details.originId,
        prospectStatusId: this.details.prospectStatusId
      })
      await this.countCandidates(this.details.id)
      await this.loadTypeHistory()
      this.$emit('closeMenu')
      this.$emit('openCandidateList')
    },
    async searchCompany(value) {
      if (value.length < 2) return

      debounce(this.loadCompanies({ query: value, refresh: true }), 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.selection-button {
  div {
    & > em,
    & > strong {
      color: var(--palette-terra-coral-100, #dd7e6f);
    }
  }

  button {
    height: fit-content !important;
  }
}

.status-label {
  display: flex;
  height: 20px;
  padding: 0px 8px;
  align-items: center;
  gap: 16px;

  &.normal {
    border: 1px solid var(--light-ui-border, #e1e1eb);
  }

  &.info {
    color: var(--ui-white, #fff);
    background: var(--light-system-info-100, #5489d9);
  }

  &.referral {
    color: #fff;
    background: var(--light-uibg-accent, rgba(6, 25, 119, 0.15));
  }

  &.warning {
    background: var(--light-system-warning-100, #c2bb00);
  }

  &.rejected {
    color: #fff;
    background: var(--light-system-danger-100, #ea2a00);
  }
}
</style>
