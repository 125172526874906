<template>
  <div>
    <div class="dialog" :class="{ active: dialog }">
      <div v-if="dialog" class="dialog-content">
        <div class="dialog-content-header">
          <div class="btn icon btn-coral no-hover">
            <em class="fal fa-building"></em>
          </div>
          <div class="flex-grow-1 h4 ml-2 mr-2">Empresa</div>
          <button class="btn text icon" @click="closeAll()">
            <em class="fal fa-times"></em>
          </button>
        </div>
        <span class="divider"></span>
        <div class="d-flex pa-2 gap-8">
          <button class="btn outlined xs" @click="scrollTo('dados')">
            Dados
          </button>
          <button class="btn outlined xs" @click="scrollTo('enderecos')">
            Endereços
          </button>
          <button class="btn outlined xs" @click="scrollTo('contatos')">
            Contatos
          </button>
          <button class="btn outlined xs" @click="scrollTo('off-limits')">
            Off limits
          </button>
          <button class="btn outlined xs" @click="scrollTo('parceiros')">
            Parceiros
          </button>
          <button class="btn outlined xs" @click="scrollTo('observacoes')">
            Observações
          </button>
        </div>
        <span class="divider"></span>
        <div class="dialog-content-body">
          <b-form v-model="isValid">
            <div ref="dados" class="pa-3">
              <div class="subheader">dados da empresa</div>
              <div class="d-flex align-end gap-16 mt-2 mb-2">
                <b-preview-file id="logo" v-model="file">
                  <template #default="{ url }">
                    <div class="avatar border-radius-0 lg">
                      <img
                        v-if="url"
                        :src="url"
                        alt=""
                        class="border-radius-0"
                      />
                      <img
                        v-else-if="currentCompany?.logo && !image"
                        :src="currentCompany?.logo"
                        alt=""
                        class="border-radius-0"
                      />
                      <em v-else class="fal fa-upload"></em>
                    </div>
                  </template>
                </b-preview-file>
                <div class="col-xs-12 col-sm-16">
                  <div
                    class="d-flex justify-between"
                    style="margin-bottom: 3px; font-size: 0.75rem"
                  >
                    <div>Nome fantasia</div>
                    <div style="color: rgba(233, 233, 242, 0.6)">
                      {{ getOptionalOrMandatory('Name') }}
                    </div>
                  </div>
                  <b-textfield
                    v-model="form.name"
                    :hint="errorName"
                    :required="true"
                    class="mb-0 flex-grow-1"
                    @change="validateNameCompany()"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-sm-16">
                <div
                  class="d-flex justify-between"
                  style="margin-bottom: 3px; font-size: 0.75rem"
                >
                  <div>CNPJ</div>
                  <div style="color: rgba(233, 233, 242, 0.6)">
                    {{ getOptionalOrMandatory('CNPJ') }}
                  </div>
                </div>
                <b-textfield
                  v-model="form.cnpj"
                  :hint="errorCnpj"
                  :required="false"
                  type="cnpj"
                  @change="validateDocumentCompany()"
                />
              </div>
              <div class="col-xs-12 col-sm-16">
                <div
                  class="d-flex justify-between"
                  style="margin-bottom: 3px; font-size: 0.75rem"
                >
                  <div>Persona</div>
                  <div style="color: rgba(233, 233, 242, 0.6)">
                    {{ getOptionalOrMandatory('Persona') }}
                  </div>
                </div>
                <b-autocomplete
                  v-model="form.personaId"
                  :options="personas"
                  :required="true"
                  option-text="name"
                  option-value="id"
                />
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div
                    class="d-flex justify-between"
                    style="margin-bottom: 3px; font-size: 0.75rem"
                  >
                    <div>Setor</div>
                    <div style="color: rgba(233, 233, 242, 0.6)">
                      {{ getOptionalOrMandatory('Setor') }}
                    </div>
                  </div>
                  <b-autocomplete
                    v-model="form.sectorId"
                    :options="sectors"
                    :required="true"
                    option-text="name"
                    option-value="id"
                  />
                </div>
                <div class="col-xs-12 col-sm-6">
                  <div
                    class="d-flex justify-between"
                    style="margin-bottom: 3px; font-size: 0.75rem"
                  >
                    <div>Porte da empresa</div>
                    <div style="color: rgba(233, 233, 242, 0.6)">
                      {{ getOptionalOrMandatory('Category') }}
                    </div>
                  </div>
                  <b-autocomplete
                    v-model="form.categoryId"
                    :options="categories"
                    :required="false"
                    option-text="name"
                    option-value="id"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <b-autocomplete
                    v-model="form.numberEmployeeId"
                    :options="numberEmployees"
                    :required="false"
                    option-text="name"
                    option-value="id"
                    label="Número de funcionários"
                  />
                </div>
                <div class="col-xs-12 col-sm-6">
                  <b-autocomplete
                    v-model="form.billingId"
                    :options="billings"
                    :required="false"
                    label="Faturamento"
                    option-text="name"
                    option-value="id"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <b-textfield
                    v-model="form.website"
                    :required="false"
                    type="url"
                    label="Website"
                  />
                </div>
                <div class="col-xs-12 col-sm-6">
                  <b-textfield
                    v-model="form.linkedin"
                    :required="false"
                    type="url"
                    label="Linkedin"
                  />
                </div>
              </div>
            </div>
            <span class="divider"></span>
            <div ref="enderecos" class="pa-3">
              <div class="subheader">Endereços</div>
              <div class="d-flex align-end gap-8">
                <b-textfield
                  v-model="currentAddress.description"
                  placeholder="Título"
                  class="mb-0 mt-2"
                />
                <b-autocomplete
                  v-model="currentAddress.address"
                  :options="addressOptions"
                  option-text="address"
                  option-title="name"
                  option-value="address"
                  placeholder="Buscar"
                  append-icon="fal fa-search"
                  class="flex-grow-1 mb-0 mt-2"
                  :on-key-up="searchPlace"
                >
                  <template #append-outer>
                    <button class="btn icon sm" @click="addAddress">
                      <em class="fal fa-plus"></em>
                    </button>
                  </template>
                </b-autocomplete>
              </div>
              <div class="list list-with-shadow mt-2">
                <div
                  v-for="i in form.address"
                  :key="i.description + '-address'"
                  class="list-item"
                >
                  <em class="fal fa-map-marker-alt"></em>
                  <div class="paragraph xs">
                    <strong>{{ i.description }}</strong>
                  </div>
                  <div class="paragraph xs flex-grow-1 text-color-light">
                    {{ i.address }}
                  </div>
                  <button class="icon" @click="deleteAddress(i.description)">
                    <em class="fal fa-trash"></em>
                  </button>
                </div>
              </div>
            </div>
            <span class="divider"></span>
            <div ref="contatos" class="pa-3">
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="subheader">Contatos comerciais</div>
                </div>
                <div class="col-xs-12 col-sm-6">
                  <b-checkbox
                    v-model="checkedContactsComercial"
                    label="Possui contatos comerciais"
                    @click="handleCheckboxChange()"
                  >
                  </b-checkbox>
                </div>
              </div>
              <div
                v-if="
                  currentCompany?.contacts?.length || checkedContactsComercial
                "
                class="contacts mt-2 overflow-auto"
              >
                <div
                  v-for="comercialContact in newCompany.comercialContacts"
                  :key="comercialContact.id"
                  class="contact text-center"
                >
                  <b-avatar
                    size="md"
                    :name="comercialContact.name"
                    style="background: #c06fdd"
                  >
                    <img
                      :src="comercialContact.photo"
                      alt="comercialContact.name"
                    />
                  </b-avatar>
                  <div class="paragraph xs mt-1 font-weight-700">
                    {{ comercialContact.name }}
                  </div>
                  <div
                    class="d-flex cursor-pointer gap-8 justify-center paragraph xs align-center"
                    @click="openRole(comercialContact, 'contact')"
                  >
                    <div
                      v-if="comercialContact.roleInCompany"
                      v-text="comercialContact.roleInCompany"
                    ></div>
                    <em v-if="!comercialContact.roleInCompany">Sem cargo</em>
                    <em class="fal fa-pencil"></em>
                  </div>
                </div>
                <button
                  class="contact text-center"
                  @click="listContacts('comercial')"
                >
                  <div class="btn icon rounded">
                    <em class="fal fa-plus"></em>
                  </div>
                  <div class="paragraph xs mt-1 text-color-light">
                    Novo contato
                  </div>
                </button>
              </div>
            </div>
            <span class="divider"></span>
            <div ref="conselho" class="pa-3">
              <div class="subheader">Conselho</div>
              <div class="contacts mt-2 overflow-auto">
                <div
                  v-for="councilContact in newCompany.councilContacts"
                  :key="councilContact.id"
                  class="contact text-center"
                >
                  <b-avatar
                    size="md"
                    :name="councilContact.name"
                    style="background: #c06fdd"
                  >
                    <img
                      :src="councilContact.photo"
                      :alt="councilContact.name"
                    />
                  </b-avatar>
                  <div class="paragraph xs mt-1 font-weight-700">
                    {{ councilContact.name }}
                  </div>
                  <div
                    class="d-flex cursor-pointer gap-8 justify-center paragraph xs align-center"
                    @click="openRole(councilContact, 'council')"
                  >
                    <div
                      v-if="councilContact.roleInCompany"
                      v-text="councilContact.roleInCompany"
                    ></div>
                    <em v-if="!councilContact.roleInCompany">Sem cargo</em>
                    <em class="fal fa-pencil"></em>
                  </div>
                </div>
                <button
                  class="contact text-center"
                  @click="listContacts('council')"
                >
                  <div class="btn icon rounded">
                    <em class="fal fa-plus"></em>
                  </div>
                  <div class="paragraph xs mt-1 text-color-light">
                    Novo contato
                  </div>
                </button>
              </div>
            </div>
            <span class="divider"></span>
            <div ref="off-limits" class="pa-3">
              <div class="subheader">Off Limits</div>
              <b-autocomplete
                v-if="true"
                v-model="offLimits"
                label="Empresa"
                class="mb-0 mt-2"
                :options="companies"
                option-value="id"
                option-text="name"
              >
                <template #append-outer>
                  <button class="btn icon sm" @click="addOffLimit(offLimits)">
                    <em class="fal fa-plus"></em>
                  </button>
                </template>
              </b-autocomplete>
              <div v-if="offLimitsToAdd" class="list list-with-shadow mt-2">
                <div
                  v-for="item in offLimitsToAdd"
                  :key="item.id"
                  class="list-item"
                >
                  <em class="fal fa-building"></em>
                  <div class="paragraph xs flex-grow-1">{{ item.name }}</div>
                  <button
                    class="icon"
                    @click="deleteItem(item.id, 'offLimitsToAdd')"
                  >
                    <em class="fal fa-trash"></em>
                  </button>
                </div>
              </div>
            </div>
            <span class="divider"></span>
            <div ref="parceiros" class="pa-3">
              <div class="subheader">parceiros</div>
              <b-autocomplete
                v-model="partnerId"
                label="Empresa"
                class="mb-0 mt-2"
                :options="companies"
                option-value="id"
                option-text="name"
                @input="(v) => console.log(v)"
              >
                <template #append-outer>
                  <button class="btn icon sm" @click="addPartner(partnerId)">
                    <em class="fal fa-plus"></em>
                  </button>
                </template>
              </b-autocomplete>
              <div class="list list-with-shadow mt-2">
                <div
                  v-for="item in partnersToAdd"
                  :key="item.id + '-partner'"
                  class="list-item"
                >
                  <em class="fal fa-building"></em>
                  <div class="paragraph xs flex-grow-1">{{ item.name }}</div>
                  <button
                    class="icon"
                    @click="deleteItem(item.id, 'partnersToAdd')"
                  >
                    <em class="fal fa-trash"></em>
                  </button>
                </div>
              </div>
              <span class="divider"></span>
              <div ref="observacoes" class="pa-3">
                <div class="subheader">observações</div>
                <b-textfield
                  v-model="form.note"
                  :multiline="true"
                  :required="false"
                  class="mb-0 mt-2"
                />
              </div>
            </div>
          </b-form>
        </div>
        <span class="divider"></span>
        <div class="dialog-content-footer justify-end">
          <button class="btn outlined sm" @click="closeAll()">Cancelar</button>
          <button
            class="btn btn-coral sm"
            :class="{ loading, disabled: !isValid }"
            @click="save()"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
    <Cargo ref="cargo" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Form from '@/enums/company.js'
import mixins from '@/mixins'
import Cargo from '@/components/modals/cargo.vue'

export default {
  components: { Cargo },
  mixins: [mixins],
  props: { company: { type: Object } },
  data() {
    return {
      currentAddress: {
        description: '',
        address: ''
      },
      dialog: false,
      image: false,
      loading: false,
      form: JSON.parse(JSON.stringify(Form)),
      file: null,
      isValid: true,
      offLimitsToAdd: [],
      partnersToAdd: [],
      comercialContacts: [],
      contact: {},
      typeContact: '',
      addressOptions: [],
      errorName: '',
      errorCnpj: '',
      checkedContactsComercial: false
    }
  },
  computed: {
    ...mapGetters({
      companies: 'company/companies',
      options: 'company/options',
      newCompany: 'company/newCompany',
      currentCompany: 'company/currentCompany',
      contacts: 'contacts/contacts',
      status: 'home/status'
    }),
    personas() {
      if (
        !this.options ||
        !this.options.personas ||
        !this.options.personas.length
      )
        return []

      const personas = Object.assign(
        [],
        this.options.personas.filter((persona) => persona.name !== 'Outros')
      )
      const [lastOption] = this.options.personas.filter(
        (persona) => persona.name === 'Outros'
      )

      personas.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })

      if (lastOption) personas.push(lastOption)

      return personas
    },
    sectors() {
      return this.options.sectors
    },
    categories() {
      return this.options.categories
    },
    numberEmployees() {
      return this.options.numberEmployees
    },
    billings() {
      return this.options.billings
    }
  },
  watch: {
    company: {
      handler(value) {
        if (value === 'new') {
          this.form = JSON.parse(JSON.stringify(Form))
        } else {
          this.mapping(value)
        }
      }
    },
    deep: true
  },
  mounted() {
    this.loadCompanyOptions()
  },
  methods: {
    ...mapActions({
      saveCompany: 'company/saveCompany',
      loadCompanyOptions: 'company/loadOptions',
      loadCompanies: 'company/loadCompanies',
      addContactsToCompany: 'company/addContactsToCompany',
      setCurrentCompany: 'company/setCurrentCompany',
      showSnackbar: 'snackbar/showSnackbar',
      setSelectedContacts: 'company/setSelectedContacts',
      clearCompany: 'company/clearCompany',
      getCompanyById: 'company/getCompanyById'
    }),
    ...mapMutations({
      clearContacts: 'company/CLEAR_CONTACTS',
      clearCompany: 'company/CLEAR_COMPANY_ALL'
    }),
    handleCheckboxChange() {
      this.newCompany.comercialContacts = []
      this.checkedContactsComercial = !this.checkedContactsComercial
    },
    getOptionalOrMandatory(fieldName) {
      const fields = {
        Name: () => 'Obrigatório',
        Setor: () => 'Obrigatório',
        Persona: () => 'Obrigatório',
        Category: () => 'Obrigatório',
        CNPJ: (fase) =>
          [1, 2, 3, 4].includes(fase) ? 'Opcional' : 'Obrigatório'
      }
      const arrayObjectFase = this.status.filter(
        (object) => object?.active === true
      )
      return fields[fieldName](arrayObjectFase[0]?.id)
    },
    async validateNameCompany() {
      if (this.form.name !== '') {
        await this.$axios
          .post('company/validateName', {
            name: this.form.name
          })
          .then((result) => {
            if (result.data.length > 0) {
              this.errorName = 'Já existe uma empresa com esse nome cadastrada'
            } else {
              this.errorName = ''
            }
          })
          .catch((error) => {
            this.showSnackbar({
              message: 'Falha ao consultar nome da empresa. ' + error,
              secondsToClose: 10,
              type: 'error'
            })
          })
      }
    },
    async validateDocumentCompany() {
      if (this.form.cnpj !== '') {
        await this.$axios
          .post('company/validateDocument', {
            cnpj: this.form.cnpj
          })
          .then((result) => {
            if (result.data.length > 0) {
              this.errorCnpj = 'Já existe uma empresa com esse CNPJ cadastrado'
            } else {
              this.errorCnpj = ''
            }
          })
          .catch((error) => {
            this.showSnackbar({
              message: 'Falha ao consultar CNPJ da empresa. ' + error,
              secondsToClose: 10,
              type: 'error'
            })
          })
      }
    },
    mapping(company) {
      this.form = company
      this.form.personaId = this.form.persona?.id
      this.form.sectorId = this.form.sector?.id
      this.form.categoryId = this.form.category?.id
      this.form.numberEmployeeId = this.form.numberEmployee?.id || null
      this.form.billingId = this.form.billing?.id
      this.form.offLimitIds = this.form.offLimits.map((s) => s.id)
      this.offLimitsToAdd = this.form.offLimits
      this.form.partnerIds = this.form.partners.map((s) => s.id)
      this.partnersToAdd = this.form.partners
      this.form.address = this.form.adresses

      this.addContactsToCompany({
        type: 'contact',
        contatos: this.form.contacts
      })

      this.addContactsToCompany({
        type: 'council',
        council: this.form.councils
      })
    },
    listContacts(listType) {
      const contacts =
        this.newCompany[
          listType === 'comercial' ? 'comercialContacts' : 'councilContacts'
        ]
      this.setSelectedContacts(contacts.map((record) => record.id))
      this.$emit('openContact', listType)
    },
    open(data) {
      if (data === 'new') {
        this.image = true
        this.clearCompany()
      } else {
        this.image = false
      }
      this.loadCompanies()
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    closeAll() {
      this.dialog = false
      this.form = JSON.parse(JSON.stringify(Form))
      this.file = null
      this.offLimitsToAdd = []
      this.partnersToAdd = []
      this.contact = {}
      this.checkedContactsComercial = false
      this.clearCompany('comercialContacts')
      this.clearCompany('councilContacts')
      this.setSelectedContacts([])
    },
    scrollTo(id) {
      this.$nextTick(() => {
        this.$refs[id].scrollIntoView({ behavior: 'smooth' })
      })
    },
    addAddress() {
      if (!this.currentAddress.description || !this.currentAddress.address)
        return
      const newAddress = Object.assign({}, this.currentAddress)
      this.form.address.push(newAddress)
      this.currentAddress.description = null
      this.currentAddress.address = null
    },
    deleteAddress(description) {
      this.form.address = this.form.address.filter(
        (address) => address.description !== description
      )
    },
    addOffLimit(id) {
      if (this.offLimitsToAdd.find((item) => item.id === id)) return

      const newOffLimit = this.companies.filter((item) => item.id === id)
      this.offLimitsToAdd.push(...newOffLimit)
      this.form.offLimitIds.push(id)
    },
    searchPlace(event) {
      const term = event?.target?.value?.trim()

      if (!term || term.length < 4) return

      this.$axios
        .get('/company/address/search', {
          params: {
            term
          }
        })
        .then(({ data: result }) => {
          this.addressOptions = result
        })
    },
    addPartner(id) {
      if (this.partnersToAdd.find((item) => item.id === id)) return

      const newPartner = this.companies.filter((item) => item.id === id)
      this.partnersToAdd.push(...newPartner)
      this.form.partnerIds.push(id)
    },
    deleteItem(id, arr) {
      this[arr] = this[arr].filter((item) => item.id !== id)
      if (arr === 'partnersToAdd') {
        this.form.partnerIds = this.form.partnerIds.filter(
          (partnerId) => partnerId !== id
        )
      } else {
        this.form.offLimitIds = this.form.offLimitIds.filter(
          (offLimitId) => offLimitId !== id
        )
      }
    },
    getUploadedFileUrl() {
      if (!this.file) return

      const formData = new FormData()

      formData.append('file', this.file)

      return this.$axios
        .post('/upload/blob', formData)
        .then(({ data: result }) => result.pathName)
        .catch((error) => {
          this.showSnackbar({
            message: error.parsedErrorText || 'Falha no upload da imagem',
            secondsToClose: 5,
            type: 'error'
          })

          return Promise.reject(error)
        })
    },

    async save() {
      if (!this.isValid) return

      if (
        !this.newCompany.comercialContacts.length &&
        this.checkedContactsComercial
      ) {
        this.showSnackbar({
          message: 'É obrigatório selecionar ao menos 1 contato comercial',
          secondsToClose: 5,
          type: 'warn'
        })

        return
      }

      if (this.file) this.form.logo = await this.getUploadedFileUrl()

      if (this.errorName !== '') return

      this.loading = true

      const { success, id: companyId } = await this.saveCompany({
        ...this.form,
        comercialContacts: this.newCompany.comercialContacts.map((contact) => ({
          id: contact.id,
          companyContactId: contact.companyContactId,
          roleInCompany: contact.roleInCompany
        })),
        councilContacts: this.newCompany.councilContacts.map((contact) => ({
          id: contact.id,
          companyCouncilId: contact.companyCouncilId,
          roleInCompany: contact.roleInCompany
        }))
      })

      if (!success) {
        this.loading = false
        return
      }

      const [company] = this.companies.filter(
        (company) => company.id === companyId
      )
      await this.getCompanyById(this.currentCompany.id)

      this.showSnackbar({
        message: 'Empresa salva.',
        secondsToClose: 5,
        type: 'success'
      })

      this.reset()
      this.close()
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(Form))
      this.file = null
      this.offLimitsToAdd = []
      this.partnersToAdd = []
      this.contact = {}
      this.loading = false
      this.currentAddress.description = null
      this.currentAddress.address = null
      this.clearCompany()
      this.setSelectedContacts([])
    },
    openRole(contact, type) {
      const key = type === 'contact' ? 'companyContactId' : 'companyCouncilId'

      this.$refs.cargo.open(
        {
          contactId: contact.id,
          companyId: this.company.id,
          role: contact.roleInCompany,
          recordId: contact[key]
        },
        type
      )
    },
    closeCargo() {
      this.$refs.cargo.close()
    }
  }
}
</script>
