import moment from 'moment/moment'

const isInteger = (number) => {
  if (!number) return false
  return !isNaN(parseInt(number.toString()))
}

export default {
  namespaced: true,
  state: {
    stages: {
      PROSPECT: 1,
      LEAD: 2,
      BUSINESS: 3,
      PROJECTS: 4,
      PLACEDS: 5
    },
    status: [
      {
        id: 1,
        name: 'Prospect',
        color: 'lavanda',
        icon: 'bullhorn',
        count: 0,
        active: true,
        data: [],
        filters: {
          statusId: '',
          priorityId: ''
        }
      },
      {
        id: 2,
        name: 'Leads',
        color: 'jambo',
        icon: 'users',
        count: 0,
        data: [],
        filters: {
          statusId: '',
          priorityId: ''
        }
      },
      {
        id: 3,
        name: 'Negócios',
        color: 'turquesa',
        icon: 'badge-dollar',
        count: 0,
        data: [],
        filters: {
          statusId: '',
          priorityId: ''
        }
      },
      {
        id: 4,
        name: 'Projetos',
        color: 'esmeralda',
        icon: 'folder-user',
        count: 0,
        data: [],
        filters: {
          statusId: '',
          priorityId: ''
        }
      },
      {
        id: 5,
        name: 'Placeds',
        color: 'liquen',
        icon: 'user-tie',
        count: 0,
        data: [],
        filters: {
          statusId: '',
          priorityId: ''
        }
      }
    ],
    priorities: [],
    origins: [],
    serviceTypes: [],
    positionStatus: {
      prospect: [],
      lead: [],
      business: [],
      businessReject: [],
      project: [],
      placed: [],
      monitoringFraquency: []
    },
    userFavorites: [],
    closesDates: []
  },
  getters: {
    stages: (state) => state.stages,
    status: (state) => state.status,
    priorities: (state) => state.priorities,
    origins: (state) => state.origins,
    serviceTypes: (state) => state.serviceTypes,
    positionStatus: (state) => state.positionStatus,
    prospectsData: (state) => state.status[0].data,
    leadsData: (state) => state.status[1].data,
    businessData: (state) => state.status[2].data,
    projectsData: (state) => state.status[3].data,
    placedsData: (state) => state.status[4].data,
    closesDates: (state) => state.closesDates,
    filteredProspectsData: (state) => {
      const { statusId, priorityId } = state.status[0].filters
      return state.status[0].data.filter((record) => {
        if (!statusId && !priorityId) return true
        if (!priorityId) return record.prospectStatusId === statusId
        if (!statusId) return record.internalFupPriorityId === priorityId
        return (
          record.prospectStatusId === statusId &&
          record.internalFupPriorityId === priorityId
        )
      })
    },
    filteredLeadsData: (state) => {
      const { statusId, priorityId } = state.status[1].filters
      return state.status[1].data.filter((record) => {
        if (!statusId && !priorityId) return true
        if (!priorityId) return record.leadStatusId === statusId
        if (!statusId) return record.internalFupPriorityId === priorityId
        return (
          record.leadStatusId === statusId &&
          record.internalFupPriorityId === priorityId
        )
      })
    },
    filteredBusinessData: (state) => {
      const { statusId, priorityId } = state.status[2].filters
      return state.status[2].data.filter((record) => {
        if (!statusId && !priorityId) return true
        if (!priorityId) return record.businessStatusId === statusId
        if (!statusId) return record.internalFupPriorityId === priorityId
        const data =
          record.businessStatusId === statusId &&
          record.internalFupPriorityId === priorityId
        return data
      })
    },
    filteredProjectsData: (state) => {
      // todo, replace this with the correct filter
      // from projects endpoint
      const { statusId, priorityId } = state.status[3].filters
      return state.status[3].data.filter((record) => {
        if (!statusId && !priorityId) return true
        if (!priorityId) return record.businessStatusId === statusId
        if (!statusId) return record.internalFupPriorityId === priorityId
        const data =
          record.businessStatusId === statusId &&
          record.internalFupPriorityId === priorityId
        return data
      })
    },
    filteredPlacedsData: (state) => {
      // todo, replace this with the correct filter
      // from projects endpoint
      const { statusId, priorityId } = state.status[4].filters
      return state.status[4].data.filter((record) => {
        if (!statusId && !priorityId) return true
        if (!priorityId) return record.businessStatusId === statusId
        if (!statusId) return record.internalFupPriorityId === priorityId
        const data =
          record.businessStatusId === statusId &&
          record.internalFupPriorityId === priorityId
        return data
      })
    },
    userFavorites: (state) => state.userFavorites
  },
  mutations: {
    SET_ACTIVE_ITEM (state, id) {
      const [status] = state.status.filter((status) => status.id === id)
      if (status) status.active = true
    },
    SET_DESACTIVE_ITEM (state, id) {
      const [status] = state.status.filter((status) => status.id === id)
      if (status) status.active = false
    },
    SET_PROSPECTS_DATA (state, data) {
      state.status[0].data = data
    },
    SET_LEADS_DATA (state, data) {
      state.status[1].data = data
    },
    SET_BUSINESS_DATA (state, data) {
      state.status[2].data = data
    },
    SET_PROJECTS_DATA (state, data) {
      state.status[3].data = data
    },
    SET_PLACEDS_DATA (state, data) {
      state.status[4].data = data
    },
    APPEND_POSITION (state, { position, stageId }) {
      const [stage] = state.status.filter((status) => status.id === stageId)

      if (!stage) return

      stage.data = [position, ...stage.data]
    },
    UPDATE_POSITION (state, { position, stageId }) {
      const [stage] = state.status.filter((status) => status.id === stageId)

      if (!stage) return

      stage.data = stage.data.map((record) => {
        if (record.id !== position.id) return record

        return {
          ...record,
          ...position
        }
      })
    },
    SET_PROSPECTS_FILTER (state, filters) {
      state.status[0].filters = filters
    },
    SET_LEADS_FILTER (state, filters) {
      state.status[1].filters = filters
    },
    SET_BUSINESS_FILTER (state, filters) {
      state.status[2].filters = filters
    },
    SET_PROJECTS_FILTER (state, filters) {
      state.status[3].filters = filters
    },
    SET_PLACEDS_FILTER (state, filters) {
      state.status[4].filters = filters
    },
    SET_USER_FAVORITES (state, data) {
      state.userFavorites = data
    },
    SET_PRIORITIES (state, data) {
      state.priorities = data
    },
    SET_ORIGINS (state, data) {
      state.origins = data
    },
    SET_SERVICE_TYPES (state, data) {
      state.serviceTypes = data
    },
    SET_PROSPECT_STATUS (state, data) {
      state.positionStatus.prospect = data
    },
    SET_LEAD_STATUS (state, data) {
      state.positionStatus.lead = data
    },
    SET_BUSINESS_STATUS (state, data) {
      state.positionStatus.business = data
    },
    SET_PLACED_STATUS (state, data) {
      state.positionStatus.placed = data
    },
    SET_PROJECTS_STATUS (state, data) {
      state.positionStatus.project = data
    },
    SET_PLACEDS_STATUS (state, data) {
      state.positionStatus.placeds = data
    },
    SET_MONITORING_FRAQUENCY (state, data) {
      state.positionStatus.monitoringFraquency = data
    },
    SET_BUSINESS_REJECT_STATUS (state, data) {
      state.positionStatus.businessReject = data
    },
    SET_CLOSES_DATES (state, data) {
      state.closesDates = data
    }
  },
  actions: {
    setNull ({ commit }) {
      commit('SET_PROSPECTS_DATA', [])
      commit('SET_LEADS_DATA', [])
      commit('SET_BUSINESS_DATA', [])
      commit('SET_PROJECTS_DATA', [])
      commit('SET_PLACEDS_DATA', [])
    },
    filterStatus ({ commit }, data) {
      const commits = {
        1: 'SET_PROSPECTS_DATA',
        2: 'SET_LEADS_DATA',
        3: 'SET_BUSINESS_DATA',
        4: 'SET_PROJECTS_DATA',
        5: 'SET_PLACEDS_DATA'
      }

      commit(commits[data[0].stepId], data)
    },
    changeActivePhase ({ commit, dispatch, state }, id) {
      state.status.forEach((item) => commit('SET_DESACTIVE_ITEM', item.id))
      commit('SET_ACTIVE_ITEM', id)
      dispatch('session/setCurrentStep', id, { root: true })
    },
    loadPriorities ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/priorities')
        .then((response) => {
          commit('SET_PRIORITIES', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de prioridades',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadOrigins ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/origins')
        .then((response) => {
          commit('SET_ORIGINS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de origens',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadServiceTypes ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/service-types')
        .then((response) => {
          commit('SET_SERVICE_TYPES', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de tipos de serviço',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadProspectStatus ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/prospect/status')
        .then((response) => {
          commit('SET_PROSPECT_STATUS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de status de prospect',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadLeadStatus ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/lead/status')
        .then((response) => {
          commit('SET_LEAD_STATUS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de status de lead',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadBusinessStatus ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/business/status')
        .then((response) => {
          commit('SET_BUSINESS_STATUS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de status de negócios',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadPlacedStatus ({ commit, dispatch }) {
      const { $axios } = this.state
      return $axios
        .get('/pipeline/placed/status')
        .then((response) => {
          commit('SET_PLACED_STATUS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de status de placeds',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadMonitoringStatus ({ commit, dispatch }) {
      const { $axios } = this.state
      return $axios
        .get('/pipeline/monitoring/status')
        .then((response) => {
          commit('SET_MONITORING_FRAQUENCY', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de periodicidade de acompanhamento',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadProjectsStatus ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/project/status')
        .then((response) => {
          commit('SET_PROJECTS_STATUS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de status de projetos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadBusinessRejectStatus ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/business/reject-status')
        .then((response) => {
          commit('SET_BUSINESS_REJECT_STATUS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de status de rejeição de negócios',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadStatus ({ dispatch }) {
      return Promise.all([
        dispatch('loadProspectStatus'),
        dispatch('loadLeadStatus'),
        dispatch('loadBusinessStatus'),
        dispatch('loadProjectsStatus'),
        dispatch('loadBusinessRejectStatus'),
        dispatch('loadPlacedStatus'),
        dispatch('loadMonitoringStatus')
      ])
    },
    getOnlyProspects ({ commit, dispatch }, filter = {}) {
      const { $axios } = this.state
      const parameters = []
      if (filter) {
        if (filter.limit) parameters.push(`limit=${filter.limit}`)
        if (filter.page) parameters.push(`page=${filter.page}`)
      }
      return $axios
        .get(`/pipeline/step/1?${parameters.join('&')}`)
        .then((response) => {
          if (
            this.state?.home?.status &&
            this.state?.home.status[0]?.data?.length > 0
          ) {
            const idList = this.state.home.status[0].data.map(
              (record) => record.id
            )
            const records = [
              ...this.state.home.status[0].data,
              ...response.data.filter((record) => !idList.includes(record.id))
            ]
            commit('SET_PROSPECTS_DATA', records)
          }
          commit('SET_PROSPECTS_DATA', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de prospects',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyLeads ({ commit, dispatch }, filter = {}) {
      const { $axios } = this.state
      const parameters = []
      if (filter) {
        if (filter.limit) parameters.push(`limit=${filter.limit}`)
        if (filter.page) parameters.push(`page=${filter.page}`)
      }
      return $axios
        .get(`/pipeline/step/2?${parameters.join('&')}`)
        .then((response) => {
          commit('SET_LEADS_DATA', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Falha ao carregar listagem de leads',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyBusiness ({ commit, dispatch }, filter = {}) {
      const { $axios } = this.state
      const parameters = []
      if (filter) {
        if (filter.limit) parameters.push(`limit=${filter.limit}`)
        if (filter.page) parameters.push(`page=${filter.page}`)
      }
      return $axios
        .get(`/pipeline/step/3?${parameters.join('&')}`)
        .then((response) => {
          if (
            this.state?.home.status &&
            this.state?.home.status[2].data.length > 0
          ) {
            const idList = this.state?.home.status[2].data.map(
              (record) => record.id
            )
            const records = [
              ...this.state.home.status[2].data,
              ...response.data.filter(
                (responsed) => !idList.includes(responsed.id)
              )
            ]
            commit('SET_BUSINESS_DATA', records)
          }
          commit('SET_BUSINESS_DATA', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de negócios',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyProjects ({ commit, dispatch }, filter = {}) {
      const { $axios } = this.state
      const parameters = []
      if (filter) {
        if (filter.limit) parameters.push(`limit=${filter.limit}`)
        if (filter.page) parameters.push(`page=${filter.page}`)
      }
      return $axios
        .get(`/pipeline/step/4?${parameters.join('&')}`)
        .then((response) => {
          if (
            this.state?.home.status &&
            this.state?.home.status[3].data.length > 0
          ) {
            const idList = this.state?.home.status[3].data.map(
              (record) => record.id
            )
            const records = [
              ...this.state.home.status[3].data,
              ...response.data.filter(
                (responsed) => !idList.includes(responsed.id)
              )
            ]
            commit('SET_PROJECTS_DATA', records)
          }
          commit('SET_PROJECTS_DATA', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de projetos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyPlaceds ({ commit, dispatch }, filter = {}) {
      const { $axios } = this.state
      const parameters = []
      if (filter) {
        if (filter.limit) parameters.push(`limit=${filter.limit}`)
        if (filter.page) parameters.push(`page=${filter.page}`)
      }
      return $axios
        .get(`/pipeline/step/5?${parameters.join('&')}`)
        .then((response) => {
          if (
            this.state?.home.status &&
            this.state?.home.status[4].data.length > 0
          ) {
            const idList = this.state?.home.status[4].data.map(
              (record) => record.id
            )
            const records = [
              ...this.state.home.status[4].data,
              ...response.data.filter(
                (responsed) => !idList.includes(responsed.id)
              )
            ]
            commit('SET_PLACEDS_DATA', records)
          }
          commit('SET_PLACEDS_DATA', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de placeds',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlySteps ({ commit, dispatch }, step) {
      const { $axios } = this.state

      return $axios
        .get(`/pipeline/step/${step}`)
        .then((response) => {
          const steps = {
            1: 'SET_PROSPECTS_DATA',
            2: 'SET_LEADS_DATA',
            3: 'SET_BUSINESS_DATA',
            4: 'SET_PROJECTS_DATA',
            5: 'SET_PLACEDS_DATA'
          }

          commit(steps[step], response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Falha ao carregar listagem da etapa',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getFavorites ({ commit, dispatch }, stepId) {
      const { $axios } = this.state

      return $axios
        .get(`/pipeline/${stepId}/favorites`)
        .then((response) => {
          if (stepId === 1) commit('SET_PROSPECTS_DATA', response.data)
          if (stepId === 2) commit('SET_LEADS_DATA', response.data)
          if (stepId === 3) commit('SET_BUSINESS_DATA', response.data)
          if (stepId === 4) commit('SET_PROJECTS_DATA', response.data)
          if (stepId === 5) commit('SET_PLACEDS_DATA', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de favoritos.',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    addFavorites ({ commit, dispatch }, pipelineId) {
      const { $axios } = this.state

      return $axios
        .post(`/pipeline/favorite/add/${pipelineId}`)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao salvar favoritos.',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    removeFavorite ({ commit, dispatch }, pipeline) {
      const { $axios } = this.state

      return $axios
        .delete(`/pipeline/favorite/remove/${pipeline}`)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao remover favoritos.',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    setFilters ({ commit }, { stage, statusId, priorityId }) {
      const filters = {
        statusId: isInteger(statusId) ? parseInt(statusId) : '',
        priorityId: isInteger(priorityId) ? parseInt(priorityId) : ''
      }

      if (stage === 1) commit('SET_PROSPECTS_FILTER', filters)
      if (stage === 2) commit('SET_LEADS_FILTER', filters)
      if (stage === 3) commit('SET_BUSINESS_FILTER', filters)
      if (stage === 4) commit('SET_PROJECTS_FILTER', filters)
      if (stage === 5) commit('SET_PLACEDS_FILTER', filters)
    },
    savePosition ({ commit, dispatch }, data) {
      const { $axios } = this.state
      if (
        !data.stepId ||
        !data.companyId ||
        !data.prospectStatusId ||
        !data.originId
      )
        return
      const action = data.id ? 'put' : 'post'
      const position = {
        stepId: data.stepId,
        contactsId: data.contacts
          ? data.contacts.map((contact) => contact.contactId)
          : [],
        responsibleContactId: data.responsible ? data.responsible.contactId : null,
        name: data.name || null,
        companyId: data.companyId,
        chanceToBecomeDeal: data.chanceToBecomeDeal || null,
        chanceToCloseDeal: data.chanceToCloseDeal || null,
        expectedDateToCloseDeal: data.expectedDateToCloseDeal || null,
        prospectStatusId: data.prospectStatusId,
        leadStatusId: data.leadStatusId || null,
        businessStatusId: data.businessStatusId || null,
        placedStatusId: data.placedStatusId || null,
        monitoringFraquencyId: data.monitoringFraquencyId || null,
        projectStatusId: data.projectStatusId || null,
        businessRejectStatusId: data.businessRejectStatusId || null,
        originId: data.originId,
        pipelineServiceTypeId: data.pipelineServiceTypeId || null,
        note: data.note || null,
        internalFupPriorityId: data.internalFupPriorityId || null,
        customerFupPriorityId: data.customerFupPriorityId || null,
        entryDatePlaced: data.entryDatePlaced || null,
        prospectStep: data.prospectStep,
        prospectStepDateCreated: data.prospectStepDateCreated,
        leadStep: data.leadStep,
        leadStepDateCreated: data.leadStepDateCreated,
        prospectStep: data.prospectStep,
        prospectStepDateCreated: data.prospectStepDateCreated,
        businessStep: data.businessStep,
        businessStepDateCreated: data.businessStepDateCreated,
        projectStep: data.projectStep,
        projectStepDateCreated: data.projectStepDateCreated,
        placedStep: data.placedStep,
        placedStepDateCreated: data.placedStepDateCreated,
        responsible: data.responsible,
        contacts: data.contacts
      }

      if (action === 'post') {
        switch (data.stepId) {
          case 1:
            position.leadStep = 1
            position.leadStepDateCreated = moment().format('YYYY-MM-DD h:mm:ss')
            break
          case 2:
            position.prospectStep = 2
            position.prospectStepDateCreated =
              moment().format('YYYY-MM-DD h:mm:ss')
            break
          case 3:
            position.businessStep = 3
            position.businessStepDateCreated =
              moment().format('YYYY-MM-DD h:mm:ss')
            break
          case 4:
            position.projectStep = 4
            position.projectStepDateCreated =
              moment().format('YYYY-MM-DD h:mm:ss')
            break
          case 5:
            position.placedStep = 5
            position.placedStepDateCreated =
              moment().format('YYYY-MM-DD h:mm:ss')
            break
        }
      }

      const [company] = this.state.company.companies.filter(
        (company) => company.id === position.companyId
      )

      return $axios[action.toLowerCase()](
        `/pipeline/${data.id || ''}`,
        position
      )
        .then(({ data }) => {
          position.id = data.id
          position.updated = data.updated || data.created

          if (action.toLowerCase() === 'post') {
            position.code = data.code
            position.created = data.created
            position.activities = []
            position.attachments = []

            commit('APPEND_POSITION', { stageId: position.stepId, position })
          }

          if (action.toLowerCase() === 'put') {
            commit('UPDATE_POSITION', { stageId: position.stepId, position })
          }

          return data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha na inserção',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getDateCloses ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/date/close')
        .then((response) => {
          commit('SET_CLOSES_DATES', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de possiveis datas de fechamento.',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyProspectsCount ({ dispatch }) {
      const { $axios } = this.state
      return $axios
        .get('/pipeline/stepCount/1')
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar contagem de prospects',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyLeadsCount ({ dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/stepCount/2')
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Falha ao carregar contagem de leads',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyBusinessCount ({ dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/stepCount/3')
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar contagem de negócios',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyProjectsCount ({ dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/stepCount/4')
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar contagem de negócios',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getOnlyPlacedsCount ({ dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/stepCount/5')
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar contagem de negócios',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getFiltered ({ dispatch }, data) {
      const { $axios } = this.state

      return $axios
        .get(`/pipeline/filter/all${data || ''}`)
        .then((response) => {
          return response.data.step
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de status de prospect',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async filterSearchText ({ state, commit, dispatch }, data) {
      const { $axios } = this.state
      if (!data.text && data.step === 1) {
        dispatch('getOnlyProspects')
        return
      } else if (!data.text && data.step === 2) {
        dispatch('getOnlyLeads')
        return
      } else if (!data.text && data.step === 3) {
        dispatch('getOnlyBusiness')
        return
      } else if (!data.text && data.step === 4) {
        dispatch('getOnlyProjects')
        return
      } else if (!data.text && data.step === 5) {
        dispatch('getOnlyPlaceds')
        return
      }

      return $axios.post(`/pipeline/search/${data.step}`, { search: data.text, favorite: data.favorite }).then(pipelines => {
        const stepFilter = state.status.filter((value) => value.id === data.step)
        stepFilter[0].data = pipelines.data
        if (data.step === 1) {
          commit('SET_PROSPECTS_DATA', stepFilter[0].data)
        } else if (data.step === 2) {
          commit('SET_LEADS_DATA', stepFilter[0].data)
        } else if (data.step === 3) {
          commit('SET_BUSINESS_DATA', stepFilter[0].data)
        } else if (data.step === 4) {
          commit('SET_PROJECTS_DATA', stepFilter[0].data)
        } else if (data.step === 5) {
          commit('SET_PLACEDS_DATA', stepFilter[0].data)
        }
      })
    },
    async setFiled ({ commit, dispatch }, data) {
      const { $axios } = this.state
      $axios
        .put(`/pipeline/${data.pipelineId}/filed`, {
          filed: data.filed
        })
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: `Posição ${data.filed ? 'arquivada' : 'desarquivada'
                } com sucesso`,
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return response.data
        })
        .catch((error) => {
          return dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao arquivar posição',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
        })
    }
  }
}
