<template>
  <div class="projects-list list-with-shadow">
    <div
      v-for="person in filteredItems"
      :key="person.id"
      :class="`${
        person.id === currentCandidate.id ? 'highlight' : ''
      } project-item list-item hover-effect ${
        winner
          ? winner.id === person.id
            ? 'highlight-winner'
            : 'disabled-simple'
          : ''
      }`"
      :item="person"
      type
      @click="selectCandidate(person)"
    >
      <b-avatar size="md" :name="person.name">
        <img v-if="person.photo" :src="person.photo" :alt="person.name" />
      </b-avatar>
      <div class="candidate-data">
        <div class="candidate-pii">
          <div class="candidate-name">
            <h4 class="text-color-default font-weight-600">
              {{ person.name }}
            </h4>
            <span v-if="winner && winner.id === person.id"> </span>
          </div>
          <div class="row">
            <small class="f-paragraph-md text-color-light font-weight-400">{{
              person.companies?.[0]?.name
            }}</small>
          </div>
          <div class="row">
            <small class="f-paragraph-xs text-color-light font-weight-400">
              {{ person.actualJob }}</small
            >
          </div>
        </div>
        <div class="candidate-statuses">
          <small
            class="f-paragraph-xxs"
            :class="filteredStatus(person.status)?.label"
            >{{ filteredStatus(person.status)?.status_description }}</small
          >
          <Rating :value="person.star" @change="changeRating($event, person)" />
          <PercentageCircle :percentage="person.compatibility" :color="red" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-center" v-if="loadMoreCandidates">
      <b-tooltip>
        <template #activator="{ on }">
          <button class="btn sm" v-on="on" @click="loadMore()">
            <em> Ver mais </em>
          </button>
        </template>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
// import { faker } from '@faker-js/faker'
import PercentageCircle from './PercentageCircle.vue'
import { mapGetters, mapActions } from 'vuex'
import Rating from './ui/Rating.vue'
// import Anexos from '@/components/contato/anexos.vue'

export default {
  name: 'Candidatos',
  components: { PercentageCircle, Rating },
  props: {
    filter: {
      type: String,
      default: ''
    },
    order: {
      type: String,
      default: ''
    },
    orderByStatus: {
      type: Number,
      default: null
    },
    company: {
      default: ''
    }
  },
  data() {
    return {
      maxStars: 5,
      page: 1,
      candidateStatus: {
        'Entrevistado Plongê': 'normal',
        'Indicação cliente': 'referral',
        'Tentando contato': 'warning',
        'Sem interesse': 'warning',
        Reprovado: 'rejected'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentPipeline: 'jobs/currentPipeline',
      candidates: 'jobs/candidates',
      currentCandidate: 'jobs/currentCandidate',
      currentTab: 'jobs/currentTab',
      loadMoreCandidates: 'jobs/viewLoadMoreCandidates'
    }),
    filteredItems() {
      let candidatesCopy = [...this.candidates]

      if (!candidatesCopy.length) {
        return []
      }

      let selectedStatusCandidates = []
      let otherStatusCandidates = []

      if (this.orderByStatus) {
        candidatesCopy.forEach((candidate) => {
          if (candidate.status === this.orderByStatus) {
            selectedStatusCandidates.push(candidate)
          } else {
            otherStatusCandidates.push(candidate)
          }
        })
      } else {
        otherStatusCandidates = [...candidatesCopy]
      }

      const sortedSelectedStatusCandidates = selectedStatusCandidates.sort(
        (object1, object2) => {
          const name1 = object1.name.toLowerCase()
          const name2 = object2.name.toLowerCase()
          return this.order === 'asc'
            ? name1.localeCompare(name2)
            : name2.localeCompare(name1)
        }
      )

      const sortedOtherStatusCandidates = otherStatusCandidates.sort(
        (object1, object2) => {
          const name1 = object1.name.toLowerCase()
          const name2 = object2.name.toLowerCase()
          return this.order === 'asc'
            ? name1.localeCompare(name2)
            : name2.localeCompare(name1)
        }
      )

      const combinedCandidates = sortedSelectedStatusCandidates.concat(
        sortedOtherStatusCandidates
      )

      const filteredCandidates = combinedCandidates.filter((item) => {
        const lowercaseFilter = this.filter.toLowerCase()
        return (
          item.name.toLowerCase().includes(lowercaseFilter) ||
          (item.actualJob &&
            item.actualJob.toLowerCase().includes(lowercaseFilter))
        )
      })

      const finalCandidates = filteredCandidates.sort((itemA, itemB) => {
        return (itemB.compatibility || 0) - (itemA.compatibility || 0)
      })

      return finalCandidates
    },
    filteredStatusCurrent(status) {
      return this.filteredStatus(status)
    },
    winner() {
      return this.candidates.find((candidate) => !!candidate.winner)
    }
  },
  watch: {
    filteredItems(value) {
      if (value.length && !this.currentCandidate.id) {
        this.selectCandidate(value[0])
        if (!this.currentCandidate.id) return
        this.loadReference(value[0].contactId)
      }
    }
  },
  methods: {
    ...mapActions({
      setCurrentCandidate: 'jobs/setCurrentCandidate',
      changeCadidateRating: 'jobs/changeCadidateRating',
      loadContactData: 'curriculum/loadContactData',
      loadFoldersContact: 'attachments/loadFoldersContact',
      setCurrentContact: 'contacts/setCurrentContact',
      loadReference: 'jobs/loadReference',
      setCurrent: 'history/setCurrent',
      loadHistory: 'history/loadHistory',
      loadCandidates: 'jobs/loadCandidates'
    }),
    async selectCandidate(candidate) {
      await this.setCurrentCandidate(candidate)
      await this.setCurrentContact({ id: this.currentCandidate.contactId })

      if (this.currentTab === 'curriculo') {
        await this.loadContactData(this.currentCandidate.contactId)
      }

      if (this.currentTab === 'anexos') {
        await this.loadFoldersContact(this.currentCandidate.contactId)
      }

      if (this.currentTab === 'referencias') {
        await this.loadReference(this.currentCandidate.contactId)
      }

      if (this.currentTab === 'historico') {
        await this.setCurrent({
          positionId: this.currentPipeline.id,
          contactId: this.currentCandidate.contactId
        })
        await this.loadHistory()
      }
    },
    filteredStatus(status) {
      switch (status) {
        case 1:
          return {
            id: 1,
            status_description: 'Entrevistado Plongê',
            label: 'normal'
          }
        case 2:
          return {
            id: 2,
            status_description: 'Indicação cliente',
            label: 'referral'
          }
        case 3:
          return {
            id: 3,
            status_description: 'Tentando contato',
            label: 'warning'
          }
        case 4:
          return {
            id: 4,
            status_description: 'Sem interesse',
            label: 'warning'
          }
        case 5:
          return {
            id: 5,
            status_description: 'Reprovado',
            label: 'rejected'
          }
        case 6:
          return {
            id: 6,
            status_description: 'Em contato',
            label: 'normal'
          }
        case 7:
          return {
            id: 7,
            status_description: 'Abordado',
            label: 'normal'
          }
        case 8:
          return {
            id: 8,
            status_description: 'Apresentado',
            label: 'normal'
          }
        case 9:
          return {
            id: 9,
            status_description: 'Entrevistado Cliente',
            label: 'normal'
          }
        case 10:
          return {
            id: 10,
            status_description: 'Off Limits Cliente',
            label: 'normal'
          }
        case 11:
          return {
            id: 11,
            status_description: 'Proposta enviada',
            label: 'normal'
          }
        case 12:
          return {
            id: 12,
            status_description: 'Proposta rejeitada',
            label: 'normal'
          }
        case 13:
          return {
            id: 13,
            status_description: 'Placed',
            label: 'normal'
          }
        case 14:
          return {
            id: 14,
            status_description: 'Não abordado',
            label: 'normal'
          }
        case 15:
          return {
            id: 15,
            status_description: 'Aprovado',
            label: 'success'
          }
      }
    },
    changeRating(newRating, candidate) {
      this.changeCadidateRating({
        contactId: candidate.contactId,
        pipelineId: this.currentPipeline.id,
        stars: newRating
      })
    },
    async loadMore() {
      this.page = this.page + 1
      return await this.loadCandidates({
        pipelineId: this.currentPipeline.id,
        params: {
          page: this.page,
          limit: 10
        }
      }).then((result) => {
        if (!result.length) {
          this.page = 1
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.candidate-list {
  display: none;

  &.active {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.projects-list {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 100px;

  // max-width: calc(100% - 60px);
  .project-item {
    &:not(:first-of-type) {
      margin: 8px 0;
    }

    &.highlight {
      border: 4px solid var(--palette-terra-manga-60, rgba(238, 178, 80, 0.6));
    }

    &.highlight-winner {
      border: 4px solid var(--light-system-success-60, #13b279);
    }

    cursor: pointer;

    display: flex;
    max-height: 88px;
    padding: 16px;
    align-items: center;
    gap: 1rem;

    .candidate-picture {
      border-radius: 100%;
      margin-right: 16px;
    }

    .candidate-data {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;

      .candidate-name {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--light-system-success-100, #13b279);

          em {
            padding: 5px;
            font-size: 0.75rem;
            color: #fff;
          }
        }
      }

      .candidate-statuses {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 16px;

        > small {
          display: flex;
          height: 20px;
          padding: 0px 8px;
          align-items: center;
          gap: 16px;

          &.normal {
            border: 1px solid var(--light-ui-border, #e1e1eb);
          }

          &.referral {
            color: #fff;
            background: var(--light-uibg-accent, rgba(6, 25, 119, 0.15));
          }

          &.warning {
            background: var(--light-system-warning-100, #c2bb00);
          }

          &.rejected {
            color: #fff;
            background: var(--light-system-danger-100, #ea2a00);
          }
        }
      }
    }
  }
}
</style>
