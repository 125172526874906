<template>
  <div class="checkbox">
    <input type="checkbox" :id="id" :value="inputValue" v-model="model" />
    <label :for="id">
      <em></em>
      <span>{{ label }}</span>
      <slot name="label" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxField',
  props: {
    label: String,
    modelValue: Array,
    inputValue: [String, Object, Number],
    id: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
